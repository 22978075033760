
import { defineComponent } from "vue";
import WinningsTable from "@/components/WinningsTable.vue";
import SimpleInfo from "@/components/SimpleInfo.vue";
import MainHeader from "@/components/MainHeader.vue";
import DigitalScoreboard from "@/components/Scoreboards/DigitalScoreboard.vue";
import StickerInfo from "@/components/StickerInfo.vue";
import CombinationGroup from "@/components/CombinationGroup.vue";
import store from "@/store";
import ScoreboardWrapper from "@/components/Scoreboards/ScoreboardWrapper.vue";
import { AudioUtils, getCurrencyIcon } from "@/utils";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import { MevahoMode, GameId } from "@/types/user";
import ModalBonus from "@/components/Modals/ModalBonus.vue";
import ModalPromo from "@/components/Modals/ModalPromo.vue";
import LastBoxes from "@/components/LastBoxes.vue";
import InputFlame from "@/components/UI/InputFlame.vue";
import InputNice from "@/components/UI/InputNice.vue";
import DoublingBoxesMevahoGold from "@/components/DoublingBoxesMevahoGold.vue";
import LastBoxesRow from "@/components/Mobile/LastBoxesRow.vue";

export default defineComponent({
  name: "main-container",
  components: {
    ModalBonus,
    ControlPanel,
    ScoreboardWrapper,
    CombinationGroup,
    StickerInfo,
    DigitalScoreboard,
    MainHeader,
    SimpleInfo,
    WinningsTable,
    ModalPromo,
    LastBoxesRow,
    DoublingBoxesMevahoGold,
    InputFlame,
    LastBoxes,
    InputNice,
  },
  data() {
    return {
      showPayout: false,
      showText: true,
      mevahoGoldExpectedWinnings: [] as number[],
    };
  },
  computed: {
    showModalInfo() {
      store.dispatch("toggleModal", "payments");
    },
    messageStatus(): string | undefined {
      return store.state.information.status;
    },
    lastPayout(): string {
      return (store.state.ui.lastPayout / 100).toFixed(2);
    },
    stepPayout(): string {
      return (store.state.ui.stepPayout / 100).toFixed(2);
    },
    currentPayout(): string {
      return store.state.currentTicket && store.state.currentTicket.payout
        ? (store.state.currentTicket.payout / 100).toFixed(2)
        : "0";
    },
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    scoreboardMode(): MevahoMode {
      return store.state.userData.settings.mevaho_mode;
    },
    gameId(): GameId {
      return store.state.currentGameId;
    },
    numBonusGames(): number {
      return store.state.bonus.numGames;
    },
    currentBonusGameCount(): number {
      return store.state.bonus.countGame;
    },
    isWidescreen(): boolean {
      return store.state.userData?.settings?.widescreen === "on";
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    promoCount(): number | undefined {
      return store.state.promo.promo?.quantity;
    },
    promoTicketsLeft(): number {
      return store.state.ui.promoTicketsLeft;
    },
    isSamePayout(): boolean {
      return this.currentPayout === this.stepPayout;
    },
    totalPayoutText(): any {
      return this.$t("payoutText");
    },
    isFruitMode(): boolean {
      return this.scoreboardMode === "fruit";
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
    isMevahoGold(): boolean {
      return store.state.currentGameId === "loto-funny-fruits";
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },

    hasBigNumber(): boolean {
      return !!this.expectedWinnings.find((number) => String(number).length > 6);
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
  },
  methods: {
    isInRange(idx: number) {
      if (this.stepCount === 0 || this.stepCount === 1) {
        return idx >= 0 && idx <= 2;
      } else if (this.stepCount === 2) {
        return idx >= 1 && idx <= 3;
      } else if (this.stepCount === 3) {
        return idx >= 2 && idx <= 4;
      } else if (this.stepCount === 4 || this.stepCount === 5) {
        return idx >= 3 && idx <= 5;
      }
    },
  },
  watch: {
    stepCount(newStepCount) {
      if (newStepCount === 0) {
        this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(0, 3);
      } else {
        const startIndex = newStepCount - 1;
        this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(startIndex, startIndex + 3);
      }
    },
  },
  created() {
    if (this.stepCount === 0) {
      this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(0, 3);
    } else {
      const startIndex = this.stepCount - 1;
      this.mevahoGoldExpectedWinnings = this.expectedWinnings.slice(startIndex, startIndex + 3);
    }
  },
});
